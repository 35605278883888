import React from 'react';
import "firebase/compat/auth";
import './App.css';
import toolVersions from './AppToolVersions';

const Footer = ({ path, pageTitle }) => {
  if (pageTitle === undefined) {
    pageTitle = "";
  }
  let resourceType = "";
  let toolType = "";
  if (path.includes("/resources/")) {
    resourceType = "GENERIC";
    if (path.includes("/Project/")) {
      resourceType = "Project";
    } else if (path.includes("/Citation/")) {
      resourceType = "Citation";
    } else if (path.includes("/ArtifactComment/")) {
      resourceType = "ArtifactComment";
    } else if (path.includes("/Recommendation/")) {
      resourceType = "Recommendation";
    } else if (path.includes("/Group/")) {
      resourceType = "Group";
    } else if (path.includes("/Composition/")) {
      if (pageTitle.includes("ComparativeEvidenceReport")) {
        toolType = "ComparativeEvidenceReport";
        resourceType = "";
      } else if (pageTitle.includes("SummaryOfFindings")) {
        toolType = "SummaryOfFindings";
        resourceType = "";
      } else if (pageTitle.includes("Recommendation")) {
        toolType = "Recommendation";
        resourceType = "";
      } else if (pageTitle.includes("Guideline")) {
        toolType = "Guideline";
        resourceType = "";
      } else if (pageTitle.includes("BaselineMeasureReport")) {
        toolType = "BaselineMeasureReport";
        resourceType = "";
      } else if (pageTitle.includes("ParticipantFlowReport")) {
        toolType = "ParticipantFlowReport";
        resourceType = "";
      } else if (pageTitle.includes("OutcomeMeasureReport")) {
        toolType = "OutcomeMeasureReport";
        resourceType = "";
      } else if (pageTitle.includes("M11Report")) {
        toolType = "M11Report";
        resourceType = "";
      } else if (pageTitle.includes("EvidenceReportPackage")) {
        toolType = "EvidenceReportPackage";
        resourceType = "";
      } else if (pageTitle.includes("EvidenceReport")) {
        toolType = "EvidenceReport";
        resourceType = "";
      } else {
        resourceType = "Composition";
      }
    } else if (path.includes("/Evidence/")) {
      resourceType = "Evidence";
    } else if (path.includes("/ResearchStudy/")) {
      if (pageTitle.includes("SystematicReview")) {
        toolType = "SystematicReview";
        resourceType = "";
      } else {
      resourceType = "ResearchStudy";
      }
    } else if (path.includes("/EvidenceReport/")) {
      resourceType = "EvidenceReport";
    } else if (path.includes("/CodeSystem/")) {
      resourceType = "CodeSystem";
    } else if (path.includes("/CodeableConcept/")) {
      resourceType = "CodeableConcept";
    } else if (path.includes("/Questionnaire/")) {
      resourceType = "Questionnaire";
    } else if (path.includes("/ValueSet/")) {
      resourceType = "ValueSet";
    } else if (path.includes("/SchemaElement/")) {
      resourceType = "SchemaElement";
    } else if (path.includes("/EvidenceVariable/")) {
      resourceType = "EvidenceVariable";
    } else if (path.includes("/ActivityDefinition/")) {
      resourceType = "ActivityDefinition";
    } else if (path.includes("/Library/")) {
      resourceType = "Library";
    } else if (path.includes("/List/")) {
      resourceType = "List";
    } else if (path.includes("/PlanDefinition/")) {
      resourceType = "PlanDefinition";
    } else if (path.includes("/Characteristic/")) {
      resourceType = "Characteristic";
    } else if (path.includes("/Measure/")) {
      resourceType = "Measure";
    } else if (path.includes("/Bundle/")) {
      resourceType = "Bundle";
    } else if (path.includes("/SoftwareScript/")) {
      resourceType = "SoftwareScript";
    }
  }
  let viewerOrBuilder = "Builder";
  if (pageTitle.includes("Viewer") || pageTitle.includes("Viewing")) {
    viewerOrBuilder = "Viewer";
  }
  if (pageTitle.includes("Adaptation")) {
    toolType = "Adaptation";
    resourceType = "";
  } else if (pageTitle.includes("Comparison")) {
    toolType = "Comparison";
    resourceType = "";
  } else if (pageTitle.includes("Classification")) {
    toolType = "Classification";
    resourceType = "";
  } else if (pageTitle.includes("Rating")) {
    toolType = "Rating";
    resourceType = "";
  } else if (pageTitle.includes("Comment")) {
    toolType = "Comment";
    resourceType = "";
  } else if (pageTitle.includes("Risk of Bias Assessment Tool")) {
    toolType = "Risk of Bias Assessment Tool";
    resourceType = "";
  } else if (pageTitle.includes("Risk of Bias Assessment Reader")) {
    toolType = "Risk of Bias Assessment Reader";
    resourceType = "";
  } else if (pageTitle.includes("RecommendationJustification")) {
    toolType = "Recommendation Justification";
    resourceType = "";
  }
  return <footer>
    <a style={{ alignSelf: "flex-end" }} href="https://computablepublishing.com"
      target="_blank" rel="noopener noreferrer">
      <img style={{ height: "28px" }} alt="" src="/png-01-wide-tiny.png" />
    </a>
    <div style={{ width: "100%", fontSize: "14px", paddingLeft: "14px", alignSelf: "flex-end" }}>
      &#169; 2021-2023 Computable Publishing LLC, Patent Pending. FEvIR<sup style={{ fontSize: "xx-small" }}>®</sup> Platform  {toolVersions.platform} uses <a href="/" target="_blank" rel="noopener noreferrer">FHIR<sup style={{ fontSize: "xx-small" }}>®</sup> current build</a>.
      {path.includes("/apidoc") && <> FEvIR API {toolVersions.api}. </>}
      {resourceType === "GENERIC" && <> Resource Viewer {toolVersions.Generic}. </>}
      {(path.includes("/createproject") || resourceType === "Project") && <> Project {viewerOrBuilder} {toolVersions.Project}. </>}
      {resourceType === "Recommendation" && <> Recommendation Viewer {toolVersions.RecommendationResource}. </>}
      {resourceType === "Evidence" && <> Evidence {viewerOrBuilder} {toolVersions.Evidence}. </>}
      {resourceType === "ResearchStudy" && <> ResearchStudy {viewerOrBuilder} {toolVersions.ResearchStudy}. </>}
      {resourceType === "EvidenceReport" && <> EvidenceReport Resource Viewer {toolVersions["EvidenceReport Resource"]}. </>}
      {resourceType === "Composition" && <> Composition {viewerOrBuilder} {toolVersions.Composition}. </>}
      {resourceType === "ActivityDefinition" && <> ActivityDefinition {viewerOrBuilder} {toolVersions.ActivityDefinition}. </>}
      {resourceType === "PlanDefinition" && <> PlanDefinition {viewerOrBuilder} {toolVersions.PlanDefinition}. </>}
      {resourceType === "Library" && <> Library {viewerOrBuilder} {toolVersions.Library}. </>}
      {resourceType === "List" && <> List {viewerOrBuilder} {toolVersions.List}. </>}
      {resourceType === "Bundle" && <> Bundle {viewerOrBuilder} {toolVersions.Bundle}. </>}
      {resourceType === "Characteristic" && <> Characteristic {viewerOrBuilder} {toolVersions.Characteristic}. </>}
      {resourceType === "Measure" && <> Measure {viewerOrBuilder} {toolVersions.Measure}. </>}
      {resourceType === "SoftwareScript" && <> SoftwareScript {viewerOrBuilder} {toolVersions.SoftwareScript}. </>}
      {resourceType === "EvidenceVariable" && <> EvidenceVariable {viewerOrBuilder} {toolVersions.EvidenceVariable}. </>}
      {resourceType === "CodeSystem" && <> CodeSystem {viewerOrBuilder} {toolVersions.CodeSystem}. </>}
      {resourceType === "ValueSet" && <> ValueSet {viewerOrBuilder} {toolVersions.ValueSet}. </>}
      {resourceType === "Questionnaire" && <> Questionnaire {viewerOrBuilder} {toolVersions.Questionnaire}. </>}
      {resourceType === "QuestionnaireResponse" && <> QuestionnaireResponse {viewerOrBuilder} {toolVersions.QuestionnaireResponse}. </>}
      {resourceType === "SchemaElement" && <> SchemaElement {viewerOrBuilder} {toolVersions.SchemaElement}. </>}
      {(path.includes("/createcohortdefinition") || resourceType === "Group") && <> Group (Cohort Definition) {viewerOrBuilder} {toolVersions.Group}. </>}
      {(path.includes("/createcitation") || resourceType === "Citation") && <> Citation {viewerOrBuilder} {toolVersions.Citation}. </>}
      {/*path.includes("/createevidence") && <> Evidence Builder {toolVersions.createevidence}. </>*/}
      {path.includes("/createcodeableconcept") && <> CodeableConcept Element Builder {toolVersions.createcodeableconcept}. </>}
      {(path.includes("/createcommentrating") || resourceType === "ArtifactComment") && <> Comment/Rating Builder {toolVersions.createcommentrating}. </>}
      {(path.includes("/createriskofbiasassessment") || toolType === "Risk of Bias Assessment Tool") && <> Risk of Bias Assessment Tool {toolVersions["Risk of Bias Assessment Tool"]}. </>}
      {toolType === "EvidenceReport" && <> EvidenceReport {viewerOrBuilder} {toolVersions.EvidenceReport}. </>}
      {toolType === "SummaryOfFindings" && <> Summary Of Findings {viewerOrBuilder} {toolVersions["Summary Of Findings"]}. </>}
      {toolType === "Recommendation" && <> Recommendation {viewerOrBuilder} {toolVersions["Recommendation"]}. </>}
      {toolType === "Guideline" && <> Guideline {viewerOrBuilder} {toolVersions["Guideline"]}. </>}
      {toolType === "M11Report" && <> M11 Report {viewerOrBuilder} {toolVersions["M11Report"]}. </>}
      {toolType === "EvidenceReportPackage" && <> EvidenceReportPackage {viewerOrBuilder} {toolVersions["EvidenceReportPackage"]}. </>}
      {toolType === "ComparativeEvidenceReport" && <> Comparative Evidence Report {viewerOrBuilder} {toolVersions["Comparative Evidence Report"]}. </>}
      {toolType === "ComparativeEvidenceSynthesisReport" && <> Comparative Evidence Synthesis Report {viewerOrBuilder} {toolVersions["Comparative Evidence Synthesis Report"]}. </>}
      {toolType === "BaselineMeasureReport" && <> Baseline Measure Report {viewerOrBuilder} {toolVersions["Baseline Measure Report"]}. </>}
      {toolType === "ParticipantFlowReport" && <> Participant Flow Report {viewerOrBuilder} {toolVersions["Participant Flow Report"]}. </>}
      {toolType === "OutcomeMeasureReport" && <> Outcome Measure Report {viewerOrBuilder} {toolVersions["Outcome Measure Report"]}. </>}
      {toolType === "Risk of Bias Assessment Reader" && <> Risk of Bias Assessment Reader {toolVersions["Risk of Bias Assessment Reader"]}. </>}
      {toolType === "Recommendation Justification" && <> Recommendation Justification {viewerOrBuilder} {toolVersions["Recommendation Justification"]}. </>}
      {toolType === "SystematicReview" && <> Systematic Review {viewerOrBuilder} {toolVersions["Systematic Review"]}. </>}
      {toolType === "Adaptation" && <> Adaptation {viewerOrBuilder} {toolVersions["Adaptation"]}. </>}
      {toolType === "Comparison" && <> Comparison {viewerOrBuilder} {toolVersions["Comparison"]}. </>}
      {toolType === "Classification" && <> Classification {viewerOrBuilder} {toolVersions["Classification"]}. </>}
      {toolType === "Rating" && <> Rating {viewerOrBuilder} {toolVersions["Rating"]}. </>}
      {toolType === "Comment" && <> Comment {viewerOrBuilder} {toolVersions["Comment"]}. </>}
      {path.includes("/medlineconvert") && <> MEDLINE-to-FEvIR Converter {toolVersions.medlineconvert}. </>}
      {path.includes("/ctgovconvert") && <> ClinicalTrials.gov-to-FEvIR Converter {toolVersions.ctgovconvert}. </>}
      {path.includes("/fhirtoctgovjson") && <> FEvIR-to-ClinicalTrials.gov Converter {toolVersions.fhirtoctgovjson}. </>}
      {path.includes("/recommendationtableviewer") && <> Recommendations Table Viewer {toolVersions.recommendationtableviewer}. </>}
      {path.includes("/knowledgeportaldemo") && <> Portal View {toolVersions.knowledgeportaldemo}. </>}
      {path.includes("/myballot") && <> My Ballot {toolVersions.myballot}. </>}
      {path.includes("/ris") && <> RIS-to-FEvIR Converter {toolVersions.ris}. </>}
      {path.includes("/docxtojsonsections") && <> DOCX-to-JSON-Sections Converter {toolVersions.docxtojsonsections}. </>}
      {path.includes("/MAGIC") && <> MAGIC-to-FEvIR Converter {toolVersions.magic}. </>}
      {path.includes("/GRADEpro") && <> GRADEpro-to-FEvIR Converter {toolVersions.gradepro}. </>}
      {path.includes("/srdr") && <> SRDR+ Project Importer {toolVersions.srdr}</>}
      {path.includes("/cedar") && <> CEDAR Search Importer {toolVersions.cedar}</>}
      {path.includes("/radar") && <> RADAR Tool {toolVersions.measuringtherate}</>}
      <> Use implies agreement to the <a href="https://fevir.net/termsofuse" target="_blank" rel="noopener noreferrer">Acceptable Use Policy</a>.</>
    </div>
    <div style={{ fontSize: "12px", paddingLeft: "14px", alignSelf: "flex-end" }}><a href="mailto:support@computablepublishing.com">support@computablepublishing.com</a></div>
  </footer>;
};

export default Footer;